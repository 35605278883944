import { useRouter } from 'next/router'
import classNames from 'classnames/bind'
import ButtonLink from 'components/ButtonLink'

import styles from './SignupForPricingPanel.module.css'
const cx = classNames.bind(styles)

const SignupForPricingPanel = ({ hasNarrowLayout = false }: { hasNarrowLayout?: boolean }) => {
  const router = useRouter()

  return (
    <div className={cx(styles.container, { hasNarrowLayout })}>
      <div>
        <h3 className={styles.heading}>Signup to Shop Wholesale</h3>
        <p className={styles.description}>Create a free Buyer account to request wholesale pricing from this Seller.</p>
        <ButtonLink
          kind="primary"
          href={`/signup/buyer?returnTo=${encodeURIComponent(
            '/recommended-sellers?skipLanding=true&seller=' + router.query.seller
          )}`}
          label="Get Wholesale Pricing"
        />
      </div>

      <div>
        <h3 className={styles.heading}>Are you a Sales Rep?</h3>
        <p className={styles.description}>
          Create a free Sales Rep Profile to connect with this Seller and sell on iPad.
        </p>
        <ButtonLink
          kind="default"
          className={styles.repSignupButton}
          href={`/signup/sales-rep?returnTo=${encodeURIComponent(
            '/recommended-sellers?skipLanding=true&seller=' + router.query.seller
          )}`}
          label="Create your Sales Rep Profile"
        />
      </div>
    </div>
  )
}

export default SignupForPricingPanel
