import Alert from 'components/Alert'
import styles from './RequestRemovedAlert.module.css'

const RequestRemovedAlert = () => (
  <Alert kind="info" iconSize={16} className={styles.container}>
    <h3 className={styles.title}>Sales Rep access removed</h3>
    <p className={styles.subtitle}>The Seller has removed your access to this catalog.</p>
  </Alert>
)

export default RequestRemovedAlert
