import ModalContainer from 'components/ModalContainer'
import Button from 'components/Button'
import styles from './RequestSuccessModal.module.css'

interface Props {
  isOpen: boolean
  onExit: () => void
}

const RequestSuccessModal = ({ isOpen, onExit }: Props) => {
  return (
    <ModalContainer className={styles.containerOverrides} isOpen={isOpen} onExit={onExit} closeable={false}>
      <div className={styles.modal}>
        <section className={styles.body}>
          <h2 className={styles.heading}>Wholesale Pricing Requested!</h2>
          <p className={styles.para}>
            Success! Your request for wholesale pricing has been sent to this Seller. You’ll receive a notification once
            your request is approved by them.
          </p>
          <p className={styles.para}>
            Please note that ordering goods or services from this Seller outside the Fieldfolio marketplace (e.g., from
            the Seller&apos;s website directly or via any third party) is not permitted under the Fieldfolio{' '}
            <a href="/terms" target="_blank" rel="noopener">
              Terms of Service
            </a>
          </p>
        </section>
        <Button isBlock kind="dark" onClick={() => onExit()}>
          Agree
        </Button>
      </div>
    </ModalContainer>
  )
}

export default RequestSuccessModal
