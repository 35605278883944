import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import { useUserSession } from 'hooks'

import {
  GetBuyerPricingRequestStatus,
  GetBuyerPricingRequestStatusVariables
} from './graphql/__generated__/GetBuyerPricingRequestStatus'
import FetchingPanel from 'components/FetchingPanel'
import RequestAccess from './RequestAccess'
import RequestSentAlert from './RequestSentAlert'
import RequestRejectedAlert from './RequestRejectedAlert'
import RequestSuccessModal from './RequestSuccessModal'

import { BuyerStatusEnum, PricingRequestStatusEnum } from '../../../../../../__generated__/globalTypes'
import GET_BUYER_PRICING_REQUEST_STATUS from './graphql/GetBuyerPricingRequestStatus.graphql'
import RequestApprovedButCatalogNotInListAlert from './RequestApprovedButCatalogNotInListAlert'
import GET_CURRENT_BUYER_ACCOUNT from 'modules/signup/components/CreateBuyerForm/graphql/GetCurrentBuyerAccountData.graphql'
import { GetCurrentBuyerAccount } from 'modules/signup/components/CreateBuyerForm/graphql/__generated__/GetCurrentBuyerAccount'

type PricingRequestProps = {
  sellerId: string
  catalogIds: string[]
  isViewingSellerPage?: boolean
}

const BuyerPricingRequest = ({ sellerId, catalogIds, isViewingSellerPage = false }: PricingRequestProps) => {
  const { isBuyer } = useUserSession()
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)

  const { loading, data } = useQuery<GetBuyerPricingRequestStatus, GetBuyerPricingRequestStatusVariables>(
    GET_BUYER_PRICING_REQUEST_STATUS,
    {
      variables: {
        sellerId
      }
    }
  )

  const { data: currentBuyerData } = useQuery<GetCurrentBuyerAccount>(GET_CURRENT_BUYER_ACCOUNT)

  if (loading) {
    return <FetchingPanel />
  }

  const isBuyerBanned = data?.currentBuyer.status === BuyerStatusEnum.BANNED

  if (!isBuyer || isBuyerBanned) return null

  const pricingRequest = data?.currentBuyer?.pricingRequests?.nodes?.[0]
  const approvedCatalogIds = pricingRequest?.approvedCatalogs?.map(catalog => catalog.id) || []
  // check if any overlap between approvedCatalogIds and catalogIds
  const hasPricingAccessToOtherCatalogs =
    approvedCatalogIds.length > 0 && approvedCatalogIds.some(id => catalogIds.includes(id))
  const hasNoApprovedCatalogs = approvedCatalogIds.length === 0

  const showAlertForSelectivelyApprovedCatalogs =
    pricingRequest?.status === PricingRequestStatusEnum.APPROVED &&
    ((isViewingSellerPage && hasNoApprovedCatalogs) || (!isViewingSellerPage && !hasPricingAccessToOtherCatalogs))

  if (currentBuyerData?.currentBuyer) {
    return (
      <>
        {!pricingRequest && (
          <RequestAccess
            buyer={currentBuyerData?.currentBuyer}
            sellerId={sellerId}
            catalogIds={catalogIds}
            onSuccess={() => setShowSuccessModal(true)}
          />
        )}
        {pricingRequest && (
          <>
            {pricingRequest.status === PricingRequestStatusEnum.REQUESTED && <RequestSentAlert />}
            {pricingRequest.status === PricingRequestStatusEnum.REJECTED && <RequestRejectedAlert />}
            {showAlertForSelectivelyApprovedCatalogs && <RequestApprovedButCatalogNotInListAlert />}
          </>
        )}
        <RequestSuccessModal isOpen={showSuccessModal} onExit={() => setShowSuccessModal(false)} />
      </>
    )
  }

  return null
}

export default BuyerPricingRequest
