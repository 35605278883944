import React from 'react'
import Link from 'next/link'
import classNames from 'classnames'

import { useUserSession } from 'hooks'

import Alert from 'components/Alert'

import { BuyerStatusEnum } from '../../../../../../__generated__/globalTypes'

import styles from './BannedBuyerAlert.module.css'

const cx = classNames.bind(styles)

type BannedBuyerAlertProps = {
  className?: string
}

const BannedBuyerAlert = ({ className }: BannedBuyerAlertProps) => {
  const { isBuyer, currentAccount } = useUserSession()
  const isBanned = currentAccount?.buyer?.status === BuyerStatusEnum.BANNED

  if (!isBuyer) return null

  return (
    <>
      {isBanned && (
        <div className={cx(styles.container, className)}>
          <Alert className={styles.alertContainer} kind="warning" hasBar>
            <h4 className={styles.alertTitle}>Your buyer account has been banned</h4>
            <p className={styles.alertContent}>
              Due to concerns from our Sellers, your account has been banned.
              <br />
              You can no longer request pricing, place orders, or view pricing information.
              <br />
              For further assistance, please {''}
              <Link href="mailto: support@fieldfolio.com">
                <a className={styles.link}>
                  <u>contact support</u>
                </a>
              </Link>
              .
            </p>
          </Alert>
        </div>
      )}
    </>
  )
}

export default BannedBuyerAlert
