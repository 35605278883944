import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import Button from 'components/Button'
import ModalContainer from 'components/ModalContainer'
import { ModalContainerProps } from 'components/ModalContainer/ModalContainer'

import { useShoppingCart } from 'modules/buyer-hub/checkout/components/ShoppingCartContext'

import styles from './InProgressOrderModal.module.css'

type InProgressOrderModalProps = {
  className?: string
  catalogId: string
} & Omit<ModalContainerProps, 'isOpen' | 'onExit'>

const InProgressOrderModal = ({ className, catalogId, ...rest }: InProgressOrderModalProps) => {
  const router = useRouter()
  const {
    fetchOrdersSummaryResult: { data }
  } = useShoppingCart()

  const [isOpen, setIsOpen] = useState(false)
  const [confirmDoLater, setConfirmDoLater] = useState(false)
  const [nextUrl, setNextUrl] = useState('')

  const handleShowModal = () => {
    setIsOpen(prevState => !prevState)
  }

  const draftOrder = data?.currentBuyer?.orders?.nodes.filter(order => order.catalogId === catalogId)[0]
  const displayName = draftOrder?.seller?.displayName ?? ''
  const preventRouteChange = Boolean(draftOrder) && !confirmDoLater

  useEffect(() => {
    const handleBeforeHistoryChange = (url: string) => {
      const allowedUrlParts = url.split('/').filter(part => part !== '')
      const allowedUrls = [`/${router.query.seller}`, `/bh/checkout/order/${draftOrder?.id}/cart`]

      const urlShowModalConditions =
        // Url does not contain seller or catalog query params
        !allowedUrlParts.some(part => part === router.query.seller || part === router.query.catalog) &&
        // Url is not a checkout url
        !allowedUrls.some(allowedUrl => allowedUrl === url) &&
        (draftOrder?.orderLineItems?.length || 0) > 0

      if (preventRouteChange && urlShowModalConditions) {
        setNextUrl(url)
        setIsOpen(true)

        // TODO: improve this so we're not poluting the console/sentry with errors
        //
        // Doesn't seem to be any better way currently to prevent the route change with next/router
        router.events.emit('routeChangeError', '', url, { shallow: false })
        throw 'Route Cancelled (please ignore)'
      }
    }

    router.events.on('beforeHistoryChange', handleBeforeHistoryChange)
    return () => {
      router.events.off('beforeHistoryChange', handleBeforeHistoryChange)
    }
  }, [
    draftOrder?.id,
    draftOrder?.orderLineItems?.length,
    preventRouteChange,
    router.events,
    router.query.catalog,
    router.query.product,
    router.query.seller
  ])

  return (
    <ModalContainer {...rest} className={className} onExit={handleShowModal} isOpen={isOpen} closeable={false}>
      <div className={styles.modalContainer}>
        <h4 className={styles.header}>Complete your order</h4>

        <p className={styles.text}>Don&apos;t forget to complete your order for {displayName}!</p>

        <div className={styles.modalFooter}>
          <Button
            kind="primary"
            icon="arrow-right"
            iconPosition="right"
            onClick={() => {
              handleShowModal()
              setConfirmDoLater(true)
              draftOrder?.id && router.push(`/bh/checkout/order/${draftOrder.id}/cart`)
            }}
            isBlock>
            Checkout now
          </Button>

          <Button
            kind="link"
            onClick={() => {
              handleShowModal()
              setConfirmDoLater(true)
              nextUrl && router.push(nextUrl)
            }}
            isBlock>
            I’ll do it later
          </Button>
        </div>
      </div>
    </ModalContainer>
  )
}

export default InProgressOrderModal
