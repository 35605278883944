import Link from 'next/link'

import Alert from 'components/Alert'
import styles from './RequestSentAlert.module.css'

const RequestSentAlert = () => (
  <Alert kind="info" icon="message-circle" iconSize={16} className={styles.container}>
    <h3 className={styles.title}>Sales Rep access requested</h3>
    <p className={styles.subtitle}>
      The Seller has received your request and can now review your Sales Rep Profile. All Sales Rep requests are subject
      to our{' '}
      <Link href="/terms">
        <a target="_blank">Terms of Service</a>
      </Link>
      .
    </p>
  </Alert>
)

export default RequestSentAlert
