import Alert from 'components/Alert'
import HelpLink from 'components/HelpLink'

import styles from './RequestApprovedAlert.module.css'

const RequestApprovedAlert = () => (
  <Alert color="tan" iconSize={16} className={styles.container} hideIcon>
    <h3 className={styles.title}>Make sales with Fieldfolio for iPad</h3>
    <p className={styles.subtitle}>Use our iPad app for Sales Reps to make sales from this catalog.</p>

    <HelpLink
      label="Learn more about selling with iPad"
      href={`${process.env.NEXT_PUBLIC_HELP_URL}/en/collections/3472127-sales-reps`}
    />
  </Alert>
)
export default RequestApprovedAlert
