import React from 'react'
import { useMemo } from 'react'
import Link from 'next/link'

import Alert from 'components/Alert'
import Button, { ButtonProps } from 'components/Button/Button'
import Icon from 'components/Icon'
import {
  CatalogRepPromotionalAuTerritoriesEnum,
  CatalogRepPromotionalNzTerritoriesEnum
} from '../../../../../../__generated__/globalTypes'

import classNames from 'classnames'

import styles from './RequestAccessPromotionalAlert.module.css'

type CatalogPromotionModalProps = {
  className?: string
  previewMode?: boolean
  sellerName?: string | null
  auTerritories?: CatalogRepPromotionalAuTerritoriesEnum[] | null
  nzTerritories?: CatalogRepPromotionalNzTerritoriesEnum[] | null
  buttonProps?: ButtonProps
  showFooterNote?: boolean
}

const RequestAccessPromotionalAlert = ({
  className,
  previewMode = false,
  auTerritories,
  nzTerritories,
  sellerName = 'the Seller',
  buttonProps,
  showFooterNote = true
}: CatalogPromotionModalProps) => {
  const allAUChecked = useMemo(() => {
    return (
      Array.isArray(auTerritories) &&
      [...auTerritories].sort().toString() === Object.values(CatalogRepPromotionalAuTerritoriesEnum).toString()
    )
  }, [auTerritories])

  const allNZChecked = useMemo(() => {
    return (
      Array.isArray(nzTerritories) &&
      [...nzTerritories].sort().toString() === Object.values(CatalogRepPromotionalNzTerritoriesEnum).toString()
    )
  }, [nzTerritories])

  const nzTerritoriesValues = { NI: 'North Island', SI: 'South Island' }

  const auList = allAUChecked ? ['Australia'] : [...(auTerritories ?? [])].sort()
  const nzList = allNZChecked
    ? ['New Zealand']
    : [...(nzTerritories ?? [])].sort().map(territory => nzTerritoriesValues[territory])
  const locationList = [...auList, ...nzList]
  let locationString = ''

  if (locationList.length === 1) {
    locationString = locationList[0]
  } else {
    locationString = locationList.reduce((previous: string, current: string, index) => {
      if (index === 0) {
        return `${current}`
      } else if (index === locationList.length - 1) {
        return `${previous} and ${current}`
      } else {
        return `${previous}, ${current}`
      }
    }, '')
  }

  return (
    <div className={styles.wrapper}>
      <Alert
        hideIcon={true}
        kind="info"
        className={classNames(styles.alert, className, { [styles.isPreviewMode]: previewMode })}>
        <div className={styles.container}>
          <div className={styles.left}>
            <img src="/seller-hub/promotion-pricing-request.svg" alt="Image" className={styles.image} />
          </div>

          <div className={styles.right}>
            <div className={styles.content}>
              <h3 className={styles.title}>This Seller is looking for Sales Reps!</h3>

              <p className={styles.description}>
                {sellerName} needs Sales Reps{locationString ? ` in ${locationString}` : ''}.
              </p>

              <p className={styles.description}>Ask for access to this catalog and sell on Fieldfolio for iPad.</p>

              <Button type="button" kind="primary" className={styles.button} {...buttonProps}>
                Request Sales Rep Access
              </Button>

              {showFooterNote && (
                <p className={styles.footerNote}>
                  Make sure that you have independently verified a Seller using their email and/or phone number before
                  requesting to sell for them through Fieldfolio. All Sales Rep requests are subject to our{' '}
                  <Link href="/terms">
                    <a target="_blank">
                      Terms of Service <Icon kind="external-link" color="var(--colorBlue8)" size={12} />
                    </a>
                  </Link>
                </p>
              )}
            </div>
          </div>
        </div>
      </Alert>
    </div>
  )
}

export default RequestAccessPromotionalAlert
