import React from 'react'
import { useUserSession } from 'hooks'

import SignupForPricingPanel from 'modules/marketplace/pricing/components/SignupForPricingPanel'
import BuyerPricingRequest from 'modules/marketplace/pricing/components/BuyerPricingRequest'
import RepPricingRequest from 'modules/marketplace/pricing/components/RepPricingRequest'

type Props = {
  sellerId: string
  catalogIds: string[]
  hasNarrowLayout?: boolean
  isViewingSellerPage?: boolean
}

const PricingRequestPanel = ({ sellerId, catalogIds, hasNarrowLayout = false, isViewingSellerPage = false }: Props) => {
  const { isGuest, isRep, isBuyer } = useUserSession()

  return (
    <>
      {isGuest && <SignupForPricingPanel hasNarrowLayout={hasNarrowLayout} />}
      {isRep && <RepPricingRequest sellerId={sellerId} catalogIds={catalogIds} />}
      {isBuyer && (
        <BuyerPricingRequest sellerId={sellerId} catalogIds={catalogIds} isViewingSellerPage={isViewingSellerPage} />
      )}
    </>
  )
}

export default PricingRequestPanel
