import { useRouter } from 'next/router'
import classnames from 'classnames'

import { arrayParam } from 'lib/query-helper'

import Button from 'components/Button'
import Badge from 'components/Badge'
import { ButtonProps } from 'components/Button/Button'

import { TopBrandValuesFragment } from 'modules/marketplace/common/components/SellerProfile/graphql/__generated__/TopBrandValuesFragment'

import styles from './BrandValuesBadgeButton.module.css'

type BrandValuesBadgeButtonProps = {
  className?: string
  brandValues: TopBrandValuesFragment[] | null | undefined
} & ButtonProps

const BrandValuesBadgeButton = ({ className, brandValues, ...props }: BrandValuesBadgeButtonProps) => {
  const router = useRouter()

  const brandValueIds = arrayParam(router.query.brandValueIds) || []

  const toggleBrandValueProducts = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, brandValueId: string) => {
    const newBrandValueIds = brandValueIds.includes(brandValueId)
      ? brandValueIds.filter(id => id !== brandValueId)
      : [...brandValueIds, brandValueId]

    const params = {
      ...router.query,
      brandValueIds: [newBrandValueIds.join(',')]
    }

    if (brandValues?.length === newBrandValueIds.length || newBrandValueIds.length === 0) {
      params.brandValueIds = []
    }

    router.push({
      pathname: router.pathname,
      query: params
    })

    // loose button focus
    event.currentTarget.blur()
  }

  if (!brandValues || brandValues.length === 0) return null

  return (
    <div className={classnames(styles.container, className)}>
      <h6 className={styles.title}>Top Brand Values</h6>
      <div className={styles.badgeButtonContainer}>
        {brandValues.map((bv, i) => {
          return (
            <Button
              className={styles.badgeButton}
              key={i}
              kind="brand"
              active={brandValueIds.includes(bv.brandValue.id)}
              onClick={e => toggleBrandValueProducts(e, bv.brandValue.id)}
              {...props}>
              {bv.brandValue.name}
              <Badge className={styles.productCount} style="tinted">
                {bv.productCount}
              </Badge>
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default BrandValuesBadgeButton
