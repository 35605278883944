import React from 'react'
import Link from 'next/link'

import Alert from 'components/Alert'
import Icon from 'components/Icon'
import Button from 'components/Button'

import styles from './RequestAccessAlert.module.css'

type RequestAccessAlertProps = {
  isSubmitting?: boolean
  handleRequestClick?: () => Promise<void>
}

const RequestAccessAlert = ({ isSubmitting = false, handleRequestClick }: RequestAccessAlertProps) => (
  <Alert color="tan" className={styles.container} hideIcon>
    <div className={styles.content}>
      <h3 className={styles.heading}>Request Wholesale Pricing</h3>
      <p className={styles.description}>To see wholesale pricing and make an order, request access from the Seller.</p>

      <Button
        kind="primary"
        className={styles.button}
        isSubmitting={isSubmitting}
        onClick={handleRequestClick ? handleRequestClick : undefined}>
        Request Wholesale Pricing
      </Button>

      <div className={styles.terms}>
        Make sure that you have independently verified a Seller using their email and/or phone number before requesting
        to sell for them through Fieldfolio. All Sales Rep requests are subject to our{' '}
        <Link href="/terms">
          <a target="_blank">
            Terms of Service <Icon kind="external-link" color="var(--colorBlue8)" size={12} />
          </a>
        </Link>
        .
      </div>
    </div>
  </Alert>
)

export default RequestAccessAlert
