import { useRouter } from 'next/router'
import isEqual from 'lodash.isequal'
import classnames from 'classnames'

import { arrayParam } from 'lib/query-helper'

import Button from 'components/Button'
import Badge from 'components/Badge'
import { ButtonProps } from 'components/Button/Button'

import { TopProductCategoriesFragment } from 'modules/marketplace/common/components/SellerProfile/graphql/__generated__/TopProductCategoriesFragment'

import styles from './ProductCategoriesBadgeButton.module.css'

type ProductCategoriesBadgeButtonProps = {
  className?: string
  productCategories: TopProductCategoriesFragment[]
  showCount?: boolean
} & ButtonProps

const ProductCategoriesBadgeButton = ({
  className,
  productCategories,
  showCount = false,
  ...props
}: ProductCategoriesBadgeButtonProps) => {
  const router = useRouter()
  const slugs = arrayParam(router.query.c) || []

  const toggleProductCategories = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    productCategorySlug: string[] | null
  ) => {
    if (productCategorySlug == null) return

    const newProductCategories = isEqual(slugs, productCategorySlug) ? [] : productCategorySlug
    const params: {
      c?: string[]
    } = {
      ...router.query,
      c: [newProductCategories.join(',')]
    }

    if (newProductCategories.length === 0) {
      delete params.c
    }

    router.push({
      pathname: router.pathname,
      query: params
    })

    // loose button focus
    event.currentTarget.blur()
  }

  if (!productCategories || productCategories.length === 0) return null

  return (
    <div className={classnames(styles.container, className)}>
      <h6 className={styles.title}>Popular Product Categories</h6>
      <div className={styles.badgeButtonContainer}>
        {productCategories.map((pc, i) => {
          return (
            <Button
              className={styles.badgeButton}
              key={i}
              kind="brand"
              active={slugs.includes(pc.productCategoryTaxonomy.slug)}
              onClick={e => toggleProductCategories(e, pc.productCategoryTaxonomy.slugPath)}
              {...props}>
              {pc.productCategoryTaxonomy.name}
              {showCount && (
                <Badge className={styles.productCount} style="tinted">
                  {pc.productCount}
                </Badge>
              )}
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default ProductCategoriesBadgeButton
