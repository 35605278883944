import classNames from 'classnames/bind'

import { parseCountryCode } from 'lib/countries'

import Icon from 'components/Icon'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'
import GridContainer from 'modules/marketplace/layout/components/GridContainer'

import BrandValuesBadgeButton from 'modules/marketplace/catalog/components/BrandValuesBadgeButton'
import ProductCategoriesBadgeButton from 'modules/marketplace/catalog/components/ProductCategoriesBadgeButton'

import { SellerProfileFragment } from 'modules/marketplace/common/components/SellerProfile/graphql/__generated__/SellerProfileFragment'
import { GetMarketplaceSeller_marketplaceSeller_marketplaceCatalogs_nodes } from 'modules/marketplace/catalog/pages/SellerPage/graphql/__generated__/GetMarketplaceSeller'

import styles from './SellerProfile.module.css'
const cx = classNames.bind(styles)

type Props = {
  seller: SellerProfileFragment
  catalog?: GetMarketplaceSeller_marketplaceSeller_marketplaceCatalogs_nodes | undefined
  isCompact?: boolean
  isHero?: boolean
}

const SellerProfile = ({ seller, catalog, isCompact = false, isHero = true }: Props) => {
  return (
    <Container>
      <GridContainer className={styles.sellerHeader}>
        {!isCompact && seller.logoThumbUrl && (
          <div className={styles.sellerLogo}>
            <img src={seller.logoThumbUrl} alt={`Wholesale ${seller.displayName}`} />
          </div>
        )}
        <div className={cx(styles.sellerDetails, { isCompact, isMissingLogo: !seller.logoThumbUrl })}>
          <div className={styles.logoNameLocationContainer}>
            {isCompact && seller.logoThumbUrl && (
              <div className={styles.sellerLogoCompact}>
                {seller.logoThumbUrl && <img src={seller.logoThumbUrl} alt={`Wholesale ${seller.displayName}`} />}
              </div>
            )}
            <div>
              {isHero ? (
                <h1 className={styles.sellerDisplayName}>{seller.displayName}</h1>
              ) : (
                <h2 className={styles.sellerDisplayName}>{seller.displayName}</h2>
              )}
              {seller.approvedPricingRequestCount > 20 && (
                <div className={styles.sellerMeta}>
                  <Icon className={styles.sellerMetaIcon} size={16} kind="star" />
                  {seller.approvedPricingRequestCount} Buyers
                </div>
              )}
              {seller.location && (
                <div className={styles.sellerMeta}>
                  <Icon className={styles.sellerMetaIcon} size={16} kind="map-pin" />
                  Based in {seller.location.city}, {seller.location.state},{' '}
                  {seller.location.country && parseCountryCode(seller.location.country)}.
                </div>
              )}
            </div>
          </div>
          <p className={styles.sellerDescription}>{seller.description}</p>
          <BrandValuesBadgeButton
            className={styles.sellerBrandValuesList}
            brandValues={catalog ? catalog.topBrandValues : seller.topBrandValues}
          />
          <ProductCategoriesBadgeButton
            productCategories={catalog ? catalog.topProductCategoryTaxonomies : seller.topProductCategoryTaxonomies}
          />
        </div>
      </GridContainer>
    </Container>
  )
}
export default SellerProfile
