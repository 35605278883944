import React, { useState } from 'react'

import Icon from 'components/Icon'
import ModalContainer from 'components/ModalContainer'
import { ModalDialogProps } from 'components/ModalDialog/ModalDialog'
import CreateBuyerForm from 'modules/signup/components/CreateBuyerForm'
import { BuyerAttributesArguments } from '../../../../../../__generated__/globalTypes'
import SearchForBusinessNumberForm from 'modules/signup/components/SearchForBusinessNumberForm'
import SelectBusinessNumberResultForm from 'modules/signup/components/SelectBusinessNumberResultForm'
import { CreateBuyerAccountMutation_createBuyer_buyer } from 'modules/signup/components/CreateBuyerForm/graphql/__generated__/CreateBuyerAccountMutation'
import { SearchForBusinessNumberFormAttributes } from 'modules/signup/components/SearchForBusinessNumberForm/useSearchForBusinessNumberForm'
import { CheckAccountDetails_accountVerification } from 'modules/signup/components/SearchForBusinessNumberForm/graphql/__generated__/CheckAccountDetails'

import styles from './UpdateBuyerAccountModal.module.css'

type UpdateBuyerAccountModalProps = {
  buyer?: BuyerAttributesArguments
  handleSubmit?: () => void
} & ModalDialogProps

// NOTE: please also see `<RenewBuyerAddressDetailsPage />` which is used to update any missing address details (but not verify the Buyer's ABN/NZBN)

const UpdateBuyerAccountModal = ({ buyer, handleSubmit, onExit, ...rest }: UpdateBuyerAccountModalProps) => {
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [businessSearchParams, setBusinessSearchParams] = useState<{
    searchData: SearchForBusinessNumberFormAttributes
    results: CheckAccountDetails_accountVerification[]
  }>()
  const [selectedBusiness, setSelectedBusiness] = useState<CheckAccountDetails_accountVerification>()

  const getForm = () => {
    switch (currentStep) {
      case 1:
        return (
          <SearchForBusinessNumberForm
            onSuccess={(businessData, formValues) => {
              setBusinessSearchParams({
                searchData: formValues,
                results: businessData
              })
              setCurrentStep(prevStep => prevStep + 1)
            }}
            initialFieldValues={
              businessSearchParams?.searchData ?? {
                country: buyer?.billingAddressAttributes?.country ?? 'AU',
                search: buyer?.taxNumber || buyer?.entityName || ''
              }
            }
          />
        )
      case 2:
        return (
          <SelectBusinessNumberResultForm
            onSuccess={selectedBusinessNumber => {
              setSelectedBusiness(() => {
                return businessSearchParams?.results.filter(
                  business => business.taxNumber === selectedBusinessNumber.business
                )[0]
              })
              setCurrentStep(prevStep => prevStep + 1)
            }}
            onGoBack={() => setCurrentStep(prevStep => prevStep - 1)}
            businessData={businessSearchParams?.results ?? []}
            searchParam={businessSearchParams?.searchData.search ?? ''}
          />
        )
      case 3:
        return (
          <>
            {selectedBusiness && buyer ? (
              <CreateBuyerForm
                onSuccess={async () => handleSubmit && handleSubmit()}
                businessData={selectedBusiness}
                initialFieldValues={
                  {
                    ...buyer,
                    phone: buyer.phone
                  } as unknown as CreateBuyerAccountMutation_createBuyer_buyer
                }
                onGoBack={() => setCurrentStep(prevStep => prevStep - 1)}
              />
            ) : null}
          </>
        )
    }
  }

  return (
    <ModalContainer {...rest} onExit={onExit} isPageScroll>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <p className={styles.modalTitle}>We’re improving security on Fieldfolio</p>
          <p className={styles.modalSubTitle}>
            To send wholesale pricing requests,
            <br />
            please update your Buyer account details
          </p>
          <p>
            <em>You’ll only have to do this once, and your details will be used for all future pricing requests.</em>
          </p>
          <a className={styles.modalClose} onClick={() => onExit()}>
            <Icon kind="x" size={16} color="currentColor" className={styles.closeIcon} />
          </a>
        </div>
        {getForm()}
      </div>
    </ModalContainer>
  )
}

export default UpdateBuyerAccountModal
