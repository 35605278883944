import ButtonLink from 'components/ButtonLink'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'

import styles from './RetailerSignupBanner.module.css'

const RetailerSignupBanner = () => {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.messaging}>
          <div>
            <h3 className={styles.title}>Ready to start ordering wholesale online?</h3>
            <div className={styles.paragraph}>Get products that your customers will love on Fieldfolio.</div>
            <ButtonLink href="/signup/buyer" kind="dark" size="large" label="Signup to Shop" />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default RetailerSignupBanner
