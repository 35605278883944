import React from 'react'
import Link from 'next/link'

import Alert from 'components/Alert'
import Icon from 'components/Icon'
import Button, { ButtonProps } from 'components/Button/Button'

import styles from './RequestAccessAlert.module.css'

type RequestAccessAlertProps = {
  buttonProps?: ButtonProps
}

const RequestAccessAlert = ({ buttonProps }: RequestAccessAlertProps) => (
  <Alert color="tan" className={styles.container} hideIcon>
    <div className={styles.content}>
      <h3 className={styles.heading}>Request to sell from this Catalog</h3>
      <p className={styles.description}>Ask for access to this catalog and sell on Fieldfolio for iPad.</p>

      <Button type="button" kind="primary" className={styles.button} {...buttonProps}>
        Request Sales Rep Access
      </Button>

      <div className={styles.terms}>
        Make sure that you have independently verified a Seller using their email and/or phone number before requesting
        to sell for them through Fieldfolio. All Sales Rep requests are subject to our{' '}
        <Link href="/terms">
          <a target="_blank">
            Terms of Service <Icon kind="external-link" color="var(--colorBlue8)" size={12} />
          </a>
        </Link>
        .
      </div>
    </div>
  </Alert>
)

export default RequestAccessAlert
