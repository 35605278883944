import Alert from 'components/Alert'
import styles from './RequestStatusAlerts.module.css'

const RequestApprovedButCatalogNotInListAlert = () => (
  <Alert icon="info" iconSize={16} className={styles.container}>
    <h3 className={styles.title}>Expecting to see pricing?</h3>
    <p className={styles.subtitle}>
      This seller has granted you wholesale pricing access for only certain catalogs in their range. Please contact the
      Seller for access to their other catalogs
    </p>
  </Alert>
)

export default RequestApprovedButCatalogNotInListAlert
