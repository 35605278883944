import Alert from 'components/Alert'
import styles from './RequestStatusAlerts.module.css'

const RequestRejectedAlert = () => (
  <Alert icon="info" iconSize={16} className={styles.container}>
    <h3 className={styles.title}>Wholesale Pricing Request Declined</h3>
    <p className={styles.subtitle}>This Seller has declined your request to access wholesale pricing.</p>
  </Alert>
)

export default RequestRejectedAlert
