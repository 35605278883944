import Alert from 'components/Alert'
import styles from './RequestRemovedAlert.module.css'

const RequestRejectedAlert = () => (
  <Alert kind="info" iconSize={16} className={styles.container}>
    <h3 className={styles.title}>Sales Rep access request declined</h3>
    <p className={styles.subtitle}>The Seller has declined your requests to sell from their catalogs.</p>
  </Alert>
)

export default RequestRejectedAlert
