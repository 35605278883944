import { useQuery } from '@apollo/client'
import { useToast } from 'components/Toast'

import FetchingPanel from 'components/FetchingPanel'
import RequestAccessAlert from './RequestAccessAlert'
import RequestAccessPromotionalAlert from './RequestAccessPromotionalAlert'
import RequestSentAlert from './RequestSentAlert'
import RequestRejectedAlert from './RequestRejectedAlert'
import RequestRemovedAlert from './RequestRemovedAlert'
import RequestApprovedAlert from './RequestApprovedAlert'
import RequestApprovedNotInSalesTeamAlert from './RequestApprovedNotInSalesTeamAlert'

import { useMutation } from 'hooks/useMutation'
import { useUserSession } from 'hooks'
import GET_REP_PRICING_REQUEST_STATUS from './graphql/GetRepPricingRequestStatus.graphql'
import CREATE_REP_PRICING_REQUEST_MUTATION from './graphql/CreateRepPricingRequestMutation.graphql'
import {
  GetRepPricingRequestStatus,
  GetRepPricingRequestStatusVariables
} from './graphql/__generated__/GetRepPricingRequestStatus'
import {
  CreateRepPricingRequestMutation,
  CreateRepPricingRequestMutationVariables
} from './graphql/__generated__/CreateRepPricingRequestMutation'
import { RepKindEnum, RepPricingRequestStatusEnum } from '../../../../../../__generated__/globalTypes'

type RepPricingRequestProps = {
  sellerId: string
  catalogIds: string[]
}

const RepPricingRequest = ({ sellerId, catalogIds }: RepPricingRequestProps) => {
  const [showToast] = useToast()

  const { isRep } = useUserSession()
  const { loading, data } = useQuery<GetRepPricingRequestStatus, GetRepPricingRequestStatusVariables>(
    GET_REP_PRICING_REQUEST_STATUS,
    {
      variables: {
        sellerId,
        catalogId: catalogIds[0]
      }
    }
  )

  const repKind = data?.currentRep.kind
  const hasPricingAccess = data?.currentRep.catalog?.hasPricingAccess ?? false
  const pricingRequest = data?.currentRep.pricingRequests.nodes[0]
  const pricingRequestStatus = pricingRequest?.status

  const seller = data?.marketplaceSeller
  const sellerName = seller?.displayName
  const isInSalesTeam = Boolean(data?.currentRep.catalog)
  const allowRepPricingRequests = seller?.marketplaceCatalog?.allowRepPricingRequests ?? false

  const repPromotionalTerritories = seller?.marketplaceCatalog?.repPromotionalTerritories ?? {
    AU: null,
    NZ: null
  }
  const auTerritories = repPromotionalTerritories.AU
  const nzTerritories = repPromotionalTerritories.NZ
  const renderPromotionalAlert =
    (Array.isArray(auTerritories) && auTerritories.length > 0) ||
    (Array.isArray(nzTerritories) && nzTerritories.length > 0)

  const [createRepPricingRequest, { loading: isSubmitting }] = useMutation<
    CreateRepPricingRequestMutation,
    CreateRepPricingRequestMutationVariables
  >(CREATE_REP_PRICING_REQUEST_MUTATION, {
    onCompleted: data => {
      if (!data.createRepPricingRequest.success) {
        showToast({ kind: 'error', message: 'Failed to submit pricing request' })
      }
    },
    onError: error => {
      console.error('Failed to request pricing', error)
    },
    refetchQueries: [GET_REP_PRICING_REQUEST_STATUS]
  })

  if (loading) {
    return <FetchingPanel />
  }

  if ((!isRep && !data?.currentRep) || !allowRepPricingRequests) return null

  const handleRequest = async () => {
    await createRepPricingRequest({
      variables: {
        input: { sellerId: sellerId, catalogIds }
      }
    })
  }
  if (pricingRequestStatus) {
    // If there is a rep pricing request, always show it's status
    switch (pricingRequestStatus) {
      case RepPricingRequestStatusEnum.REQUESTED:
        return <RequestSentAlert />

      case RepPricingRequestStatusEnum.REJECTED:
        return <RequestRejectedAlert />

      case RepPricingRequestStatusEnum.REMOVED:
        return <RequestRemovedAlert />

      case RepPricingRequestStatusEnum.APPROVED:
        if (isInSalesTeam) {
          return <RequestApprovedAlert />
        } else {
          return <RequestApprovedNotInSalesTeamAlert sellerName={sellerName ?? undefined} />
        }
    }
  } else {
    // If rep was directly invited via email, show the status
    if (hasPricingAccess) {
      if (isInSalesTeam) {
        return <RequestApprovedAlert />
      } else {
        return <RequestApprovedNotInSalesTeamAlert sellerName={sellerName ?? undefined} />
      }
    }
  }

  // Non-independent rep's cannot request access
  if (repKind !== RepKindEnum.INDEPENDENT) return null

  if (renderPromotionalAlert) {
    return (
      <RequestAccessPromotionalAlert
        sellerName={sellerName ?? ''}
        auTerritories={auTerritories}
        nzTerritories={nzTerritories}
        buttonProps={{ isSubmitting, disabled: isSubmitting, onClick: handleRequest }}
      />
    )
  }

  return <RequestAccessAlert buttonProps={{ isSubmitting, disabled: isSubmitting, onClick: handleRequest }} />
}

export default RepPricingRequest
