import classnames from 'classnames'

import Icon from 'components/Icon'
import ButtonLink from 'components/ButtonLink'

import { useShoppingCart } from 'modules/buyer-hub/checkout/components/ShoppingCartContext'

import styles from './InProgressOrderBanner.module.css'

type InProgressOrderBannerProps = {
  classNameContainer?: string
  classNameBanner?: string
  catalogId: string
  productId?: string
}

const InProgressOrderBanner = ({
  classNameContainer,
  classNameBanner,
  catalogId,
  productId
}: InProgressOrderBannerProps) => {
  const {
    fetchOrdersSummaryResult: { data }
  } = useShoppingCart()

  const draftOrder = data?.currentBuyer?.orders?.nodes.filter(order => order.catalogId === catalogId)[0]
  const orderId = draftOrder?.id ?? ''
  const orderLineItemsCount = draftOrder?.orderLineItems.length ?? 0
  const productIdsInCart = draftOrder?.orderLineItems.map(orderLineItem => orderLineItem.productId) ?? []

  if (!draftOrder || (productId && !productIdsInCart.includes(productId)) || orderLineItemsCount === 0) return null

  return (
    <div className={classnames(styles.container, classNameContainer)}>
      <div className={classnames(styles.bannerContainer, classNameBanner)}>
        <div className={styles.orderDetailsContent}>
          <div className={styles.textContent}>
            <p className={styles.orderNumber}>Order #{orderId}</p>
          </div>
        </div>

        <div className={styles.rightContent}>
          <div className={styles.shoppingCart}>
            <Icon className={styles.grayIcon} kind="shopping-cart" size={14} />
            <p className={styles.orderLineItemsCount}>{`${orderLineItemsCount} Item${
              orderLineItemsCount === 1 ? '' : 's'
            }`}</p>
          </div>

          <ButtonLink
            kind="primary"
            href={`/bh/checkout/order/${orderId}/cart`}
            icon="arrow-right"
            iconPosition="right"
            label="Checkout now"
          />
        </div>
      </div>
    </div>
  )
}

export default InProgressOrderBanner
