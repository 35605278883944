import Alert from 'components/Alert'
import styles from './RequestStatusAlerts.module.css'

const RequestSentAlert = () => (
  <Alert kind="info" icon="message-circle" iconSize={16} className={styles.container}>
    <h3 className={styles.title}>Wholesale Pricing Requested</h3>
    <p className={styles.subtitle}>Your request for wholesale pricing has been sent to this Seller.</p>
  </Alert>
)

export default RequestSentAlert
