import Alert from 'components/Alert'
import styles from './RequestApprovedNotInSalesTeamAlert.module.css'

type RequestApprovedNotInSalesTeamAlertProps = {
  sellerName?: string
}

const RequestApprovedNotInSalesTeamAlert = ({ sellerName = 'The seller' }: RequestApprovedNotInSalesTeamAlertProps) => (
  <Alert kind="info" icon="info" iconSize={16} className={styles.container}>
    <h3 className={styles.title}>Expecting to see pricing?</h3>
    <p className={styles.subtitle}>
      You’re not on the Sales Team for this catalog.
      <br />
      {sellerName} is using Sales Teams to control Sales Rep access to their catalogs. Contact this Seller to be added
      to the Sales Team for this catalog.
    </p>
  </Alert>
)

export default RequestApprovedNotInSalesTeamAlert
