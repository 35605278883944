import ButtonLink, { ButtonLinkProps } from 'components/ButtonLink/ButtonLink'
import classnames from 'classnames'

import styles from './HelpLink.module.css'

const HelpLink = ({ children, className, ...props }: ButtonLinkProps) => (
  <ButtonLink
    className={classnames(styles.buttonLink, className)}
    icon="external-link"
    iconPosition="right"
    iconSize={16}
    kind="link"
    target="_blank"
    {...props}>
    {children}
  </ButtonLink>
)

export default HelpLink
