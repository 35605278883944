import React, { useState } from 'react'
import Router from 'next/router'

import {
  CreateBuyerPricingRequestMutation,
  CreateBuyerPricingRequestMutationVariables
} from './graphql/__generated__/CreateBuyerPricingRequestMutation'
import { GetCurrentBuyerAccount_currentBuyer } from 'modules/signup/components/CreateBuyerForm/graphql/__generated__/GetCurrentBuyerAccount'
import { useToast } from 'components/Toast'
import { useMutation } from 'hooks/useMutation'
import RequestAccessAlert from './RequestAccessAlert'
import UpdateBuyerAccountModal from '../UpdateBuyerAccountModal'
import GET_BUYER_PRICING_REQUEST_STATUS from './graphql/GetBuyerPricingRequestStatus.graphql'
import CREATE_BUYER_PRICING_REQUEST_MUTATION from './graphql/CreateBuyerPricingRequestMutation.graphql'
import { PricingRequestSourceEnum, TaxNumberCheckStatusEnum } from '../../../../../../__generated__/globalTypes'

type RequestAccessProps = {
  sellerId: string
  catalogIds: string[]
  buyer: GetCurrentBuyerAccount_currentBuyer
  onSuccess: () => void
}

const RequestAccess = ({ sellerId, catalogIds, buyer, onSuccess }: RequestAccessProps) => {
  const [showToast] = useToast()
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [showUpdateBuyerModal, setShowUpdateBuyerModal] = useState<boolean>(false)

  const [createBuyerPricingRequest, { loading: isSubmitting }] = useMutation<
    CreateBuyerPricingRequestMutation,
    CreateBuyerPricingRequestMutationVariables
  >(CREATE_BUYER_PRICING_REQUEST_MUTATION, {
    onCompleted: data => {
      if (!data.createBuyerPricingRequest.success) {
        showToast({ kind: 'error', message: 'Failed to submit pricing request' })
      } else {
        onSuccess()
      }
    },
    onError: error => {
      console.error('Failed to request pricing', error)
    },
    refetchQueries: [GET_BUYER_PRICING_REQUEST_STATUS]
  })

  const sendRequest = async () => {
    await createBuyerPricingRequest({
      variables: {
        input: { sellerId: sellerId, catalogIds, source: PricingRequestSourceEnum.BUYER_REQUESTED }
      },
      update: (cache, result) => {
        if (result.data?.createBuyerPricingRequest.success) {
          cache.evict({ id: `Buyer:${buyer.id}`, fieldName: 'recommendedSellers' })
          cache.gc()
        }
      }
    })
  }

  const handleRequestClick = async () => {
    setIsFetching(true)

    if (buyer.id) {
      if (
        buyer.taxNumberCheckStatus === TaxNumberCheckStatusEnum.UNVERIFIED &&
        (!buyer.billingAddress?.country ||
          buyer.billingAddress?.country === 'AU' ||
          buyer.billingAddress?.country === 'NZ' ||
          buyer.billingAddress?.country === 'GB' ||
          buyer.billingAddress?.country === 'US')
      ) {
        setShowUpdateBuyerModal(true)
      } else if (
        buyer.taxNumberCheckStatus === TaxNumberCheckStatusEnum.VERIFIED &&
        (!buyer.billingAddress || !buyer.shippingAddress)
      ) {
        Router.push('/bh/account/renew-address-details')
      } else {
        await sendRequest()
      }
    }

    setIsFetching(false)
  }

  const handleSubmit = () => sendRequest()

  const handleModalClose = async () => {
    setShowUpdateBuyerModal(false)
  }

  return (
    <>
      <RequestAccessAlert isSubmitting={isFetching || isSubmitting} handleRequestClick={handleRequestClick} />
      <UpdateBuyerAccountModal
        buyer={buyer}
        onExit={handleModalClose}
        handleSubmit={handleSubmit}
        isOpen={!!(buyer && showUpdateBuyerModal)}
      />
    </>
  )
}

export default RequestAccess
